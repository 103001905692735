<template>
  <div class="flex-grow-1 d-flex flex-column justify-content-center">
    <h1 class="fs-20 mb-24">Login Siswa</h1>

    <form class="mb-32" @submit.prevent="handleSubmit">
      <InputField label="NISN" v-model="input.nisn" :error="errors.nisn" />

      <InputField
        class="mt-16"
        label="Password"
        v-model="input.password"
        :attrs="{ type: 'password' }"
        :error="errors.password"
      />

      <div class="d-grid mt-24">
        <button
          type="submit"
          class="btn btn-lg btn-primary"
          :class="{ 'is-loading': isSubmitting }"
          :disabled="isSubmitting"
        >
          Login
        </button>
      </div>
    </form>

    <p>
      Bukan siswa? Login sebagai
      <router-link to="/guru/login">guru</router-link> atau
      <router-link to="/admin/login">admin</router-link>.
    </p>
  </div>
</template>

<script>
import get from 'lodash/get';

import InputField from '../../components/form/InputField.vue';

export default {
  components: { InputField },

  data() {
    return { errors: {}, input: {}, isSubmitting: false };
  },

  metaInfo: {
    title: 'Login',
  },

  methods: {
    async handleSubmit() {
      try {
        this.errors = {};
        this.isSubmitting = true;
        await this.$auth.login(this.input);
      } catch (err) {
        this.errors = get(err, 'response.data.error.errors', {});
        this.$alert.requestError(err);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
